/**
 * Direttiva che crea il banner specifico per ogni pagina
 */

// import del translate
export interface IBannerDirectiveScope extends ng.IScope {
	title: string;
	page: string;
	date: Date;
	day: any;
	month: any;
	year: any;
	globalApplicationData: any;
	bgacademyApplicationData: any;
	loggedUsr: any;
	item: any;
	notificationList: any;
	notifications: any;
	bgacademyIndicators: any;
	indicators: any;
	courseDetailPromise: any;
	webinarDetailPromise: any;
	detailPromise: any;
	detail: any;
	isHome: Function;
	isTrainingPlan: Function;
	isHistory: Function;
	isNews: Function;
	isNotifications: Function;
	isUserSettings: Function;
	isCourseDetail: Function;
	isOnPremiseCourseDetail: Function;
	isOnlineCourseDetail: Function;
	goToTrainingPlan: Function;
	goToHistory: Function;
	goToUserSettings: Function;
	goToNotifications: Function;
	goToMaps: Function;
	defaultAvatar: string;
	isCourseDetailAdmin: Function;
	avatarUrl: string;
	creditList: Array<any>;
	isLibraryUser: Function;
	isLp: Function;
	isPlaylist: Function;
	isWebinarLive: any;
	isWebinarDetail: Function;
	isWebinarDetailAdmin: Function;
	isToday: Function;
	goToWebinarList: Function;
	extractedWebinarData: any;
}
angular.module('app').directive("banner", ($window, $state, $translate, BgacademyApplicationData, GlobalApplicationData, SessionStorageService) => {
	return {
		link: link,
		replace: true,
		restrict: 'AE',
		scope: {
			page: '@',
			backgroundImage: '@',
			notificationList: '=',
			bgacademyIndicators: '=',
			courseDetailPromise: '=',
			webinarDetailPromise: '=',
			avatarUrl: '='
		},
		templateUrl: 'app/shared/banner/banner.html'
	};
	function link($scope: IBannerDirectiveScope, element: any, attrs: ng.IAttributes) {
		// Lista di crediti
		$scope.creditList = [];

		// Collegamento all'oggetto principale
		$scope.bgacademyApplicationData = BgacademyApplicationData;

		if (GlobalApplicationData && GlobalApplicationData.jwtPayload && GlobalApplicationData.jwtPayload.user && !$scope.loggedUsr) {
			$scope.loggedUsr = GlobalApplicationData.jwtPayload.user;
		}

		// Preparo l'immagine di default qualora l'avatar dell'utente non ci fosse. Devo calcolarlo qui perché esso sarà il background-url() impostato tramite l'ng-style
		$scope.defaultAvatar = $scope.loggedUsr.chiaveSesso == $scope.bgacademyApplicationData.constants.MALE ? "assets/img/placeholder.png" : "assets/img/placeholder_female.png";

		$scope.indicators = $scope.bgacademyIndicators;
		$scope.detailPromise = $scope.courseDetailPromise;

		$scope.notifications = $scope.notificationList;

		// Setto la data attuale
		$scope.date = new Date();
		$scope.day = $scope.date.getDate();
		$scope.month = $scope.date.getMonth() + 1;
		$scope.year = $scope.date.getFullYear();

		//richiamo la funzione di translate
		//che prende come valore la chiave $scope.page
		// e mi restituisce la traduazione in lingua da settare

		$scope.title = $translate.instant($scope.page);

		$scope.isHome = () => {
			return $scope.page == 'home.TITLE';
		};

		$scope.isTrainingPlan = () => {
			return $scope.page == 'trainingPlan.TITLE';
		};

		$scope.isHistory = () => {
			return $scope.page == 'history.TITLE';
		};

		$scope.isLibraryUser = () => {
			return $scope.page == 'libraryUser.TITLE';
		};

		$scope.isLp = () => {
			return $scope.page == 'libraryUser.LP';
		};

		$scope.isPlaylist = () => {
			return $scope.page == 'libraryUser.PLAYLIST';
		};

		$scope.isNews = () => {
			return $scope.page == 'news.TITLE';
		};

		$scope.isNotifications = () => {
			return $scope.page == 'notifications.TITLE';
		};

		$scope.isUserSettings = () => {
			return $scope.page == 'userSettings.TITLE';
		};

		$scope.isCourseDetail = () => {
			return $scope.page == 'courseDetail.TITLE';
		};

		$scope.isWebinarDetail = () => {
			return $scope.page == 'webinarDetail.TITLE';
		};

		$scope.isCourseDetailAdmin = () => {
			return $scope.page == 'courseDetailAdmin.TITLE';
		};

		$scope.isWebinarDetailAdmin = () => {
			return $scope.page == 'webinarDetailAdmin.TITLE';
		};

		$scope.goToTrainingPlan = () => {
			// Verifico quanti elementi ho nel Session Storage
			let count = SessionStorageService.count("TRAINING_SEARCH");
			// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
			count = count ? count : 0;
			$state.go('app.bgacademyApp.trainingPlan', { searchId: count });
		};

		$scope.goToWebinarList = () => {
			$state.go('app.bgacademyApp.webinarList');
		};

		$scope.goToHistory = () => {
			$state.go('app.bgacademyApp.history');
		};

		$scope.goToUserSettings = () => {
			$state.go('app.bgacademyApp.userSettings');
		};

		$scope.goToNotifications = () => {
			$state.go('app.bgacademyApp.notifications');
		};

		$scope.goToMaps = (courseDate: any) => {
			if (courseDate && courseDate.mapLatitiude && courseDate.mapLongitude) {
				let url: string = "http://www.google.com/maps/place/" + courseDate.mapLatitiude + "," + courseDate.mapLongitude + "/@" + courseDate.mapLatitiude + "," + courseDate.mapLongitude + ",17z";
				$window.open(url, "_blank");
			}
		};

		$scope.isToday = (courseDate: number): boolean => {
			if (courseDate) {
				let courseDateDate: Date = new Date(courseDate);
				let today: Date = new Date();
				return courseDateDate.getFullYear() == today.getFullYear()
					&& courseDateDate.getMonth() == today.getMonth()
					&& courseDateDate.getDate() == today.getDate();

			}
			return false;
		};

		if ($scope.detailPromise) {
			$scope.detailPromise.then((data: any) => {
				$scope.detail = data;
				if ($scope.detail && $scope.detail.course) {
					if ($scope.detail.course.courseType) {
						if ($scope.detail.course.courseType == BgacademyApplicationData.constants.COURSE) {
							$scope.detail.course.courseTypeDesc = $translate.instant('courseCard.types.' + $scope.detail.course.courseType);
						} else if ($scope.detail.course.courseType == BgacademyApplicationData.constants.ONLINE) {
							$scope.detail.course.courseTypeDesc = $translate.instant('courseCard.types.' + $scope.detail.course.courseType);
						}
					}
					if ($scope.detail.course.courseAttributes && $scope.detail.course.courseAttributes.length) {
						let isMifidValidated = false;
						let hasMifidValue = false;
						for (let i = 0; i < $scope.detail.course.courseAttributes.length; i++) {
							if ($scope.detail.course.courseAttributes[i].courseAttributeType == 'IVASS_TYPE') {
								let newCredit = {
									name: $translate.instant('courseCard.creditTypes.' + $scope.detail.course.courseAttributes[i].courseAttributeType),
									val: $scope.detail.course.courseAttributes[i].courseAttributeValue,
									creditType: $scope.detail.course.courseAttributes[i].courseAttributeType,
									order: 2
								};
								$scope.creditList.push(newCredit);
							} else if ($scope.detail.course.courseAttributes[i].courseAttributeType == 'EFA_A_TYPE') {
								let newCredit = {
									name: $translate.instant('courseCard.creditTypes.' + $scope.detail.course.courseAttributes[i].courseAttributeType),
									val: $scope.detail.course.courseAttributes[i].courseAttributeValue,
									creditType: $scope.detail.course.courseAttributes[i].courseAttributeType,
									order: 3
								};
								$scope.creditList.push(newCredit);
							} else if ($scope.detail.course.courseAttributes[i].courseAttributeType == BgacademyApplicationData.constants.MIFID_II_TYPE
								&& $scope.detail.course.courseAttributes[i].courseAttributeValue) {
									let newCredit = {
										name: $translate.instant('courseCard.creditTypes.' + $scope.detail.course.courseAttributes[i].courseAttributeType),
										val: $scope.detail.course.courseAttributes[i].courseAttributeValue,
										creditType: $scope.detail.course.courseAttributes[i].courseAttributeType,
										order: 1
									};
									$scope.creditList.push(newCredit);
									hasMifidValue = true;
								// Verifico se è MiFID
								isMifidValidated = true;
							} else if ($scope.detail.course.courseAttributes[i].courseAttributeType == 'EFA_B_TYPE') {
								let newCredit = {
									name: $translate.instant('courseCard.creditTypes.' + $scope.detail.course.courseAttributes[i].courseAttributeType),
									val: $scope.detail.course.courseAttributes[i].courseAttributeValue,
									creditType: $scope.detail.course.courseAttributes[i].courseAttributeType,
									order: 4
								};
								$scope.creditList.push(newCredit);

							} else if ($scope.detail.course.courseAttributes[i].courseAttributeType == BgacademyApplicationData.constants.MIFID_II_TYPE) {
								
							} else if ($scope.detail.course.courseAttributes[i].courseAttributeType == 'EFP_TYPE') {
								let newCredit = {
									name: $translate.instant('courseCard.creditTypes.' + $scope.detail.course.courseAttributes[i].courseAttributeType),
									val: $scope.detail.course.courseAttributes[i].courseAttributeValue,
									creditType: $scope.detail.course.courseAttributes[i].courseAttributeType,
									order: 5
								};
								$scope.creditList.push(newCredit);
							}
						}

						if ($scope.creditList && $scope.creditList.length) {
							if (hasMifidValue && !isMifidValidated) {
								for (let m = 0, creditsLength = $scope.creditList.length; m < creditsLength; m++) {
									if ($scope.creditList[m].creditType === BgacademyApplicationData.constants.MIFID_II_TYPE) {
										$scope.creditList.splice(m, 1);
										break;
									}
								}
							}
							let compare = (a: any, b: any) => {
								if (a.order < b.order)
									return -1;
								if (a.order > b.order)
									return 1;
								return 0;
							}
							// Ordino i crediti
							$scope.creditList.sort(compare);
						}
					}

				}
			}).catch((error: any) => {
			});
		}

		if ($scope.webinarDetailPromise) {
			$scope.webinarDetailPromise.then((data: any) => {
				$scope.detail = data.itemObj;
				$scope.isWebinarLive = data.isWebinarLive;
				$scope.extractedWebinarData = {};
				if ($scope.detail && $scope.detail.item) {
					if ($scope.detail.item.itemAttributes && $scope.detail.item.itemAttributes.length) {
						for (let i = 0; i < $scope.detail.item.itemAttributes.length; i++) {
							switch ($scope.detail.item.itemAttributes[i].attributeType) {
								case 'START_TIME':
									let startDate: Date = new Date($scope.detail.item.itemAttributes[i].attributeValue); $scope.extractedWebinarData.startTime = startDate;
									$scope.extractedWebinarData.startTimeDay = startDate.getDate();
									$scope.extractedWebinarData.startTimeMonth = startDate.getMonth() + 1;
									$scope.extractedWebinarData.startTimeYear = startDate.getFullYear();
									$scope.extractedWebinarData.startTimeHours = (startDate.getHours() < 10 ? "0" : "") + startDate.getHours();
									$scope.extractedWebinarData.startTimeMinutes = (startDate.getMinutes() < 10 ? "0" : "") + startDate.getMinutes();
									break;
								case 'END_TIME':
									let endDate: Date = new Date($scope.detail.item.itemAttributes[i].attributeValue); $scope.extractedWebinarData.endTime = endDate;
									$scope.extractedWebinarData.endTimeDay = endDate.getDate();
									$scope.extractedWebinarData.endTimeMonth = endDate.getMonth() + 1;
									$scope.extractedWebinarData.endTimeYear = endDate.getFullYear();
									$scope.extractedWebinarData.endTimeHours = (endDate.getHours() < 10 ? "0" : "") + endDate.getHours();
									$scope.extractedWebinarData.endTimeMinutes = (endDate.getMinutes() < 10 ? "0" : "") + endDate.getMinutes();
									break;
							}
						}
					}

				}
			}).catch((error: any) => {
			});
		}

	}
});